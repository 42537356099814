/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Title, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"CENÍKY"}>
        <Column className="--menu pb--08 pt--08" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--22" content={"<a href=\"https://michaelasynacovafotografie.cz\">Michaela Synáčová Fotografie </a>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

              </MenuWrapBox>

              <MenuWrapBox style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz"} use={"url"} href={"https://michaelasynacovafotografie.cz"} target={""} content={"<span style=\"font-weight: 400; color: rgb(0, 0, 0);\">home</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/foceni"} target={""} content={"<span style=\"caret-color: rgb(2, 0, 0); color: rgb(2, 0, 0); font-weight: 400;\">CENÍKY</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/info/"} use={"url"} href={"https://michaelasynacovafotografie.cz/info/"} target={""} content={"<span style=\"font-weight: 400; color: rgb(4, 0, 0);\">INFO</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/kontakt/"} use={"url"} href={"https://michaelasynacovafotografie.cz/kontakt/"} target={""} content={"<div><span style=\"font-weight: normal; color: rgb(0, 0, 0);\">KONTAKT</span><br></div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-1rz2elc pb--60 pt--60" name={"gpb1bltleu8"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--102" content={"<br>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/newborn\"><br><br><br>NEWBORN\nMIMINKO<br>&nbsp;&nbsp;<br><br></a><br></span>"}>
              </Title>

              <Text className="text-box fs--102" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-97wny7 pb--60 pt--60" name={"585bggx18e8"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13101/ac1006840dd14cf9b381c58da686b0fa_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--102" content={"<br>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/rodina-par-tehotenstvi-portret\"><br><br><br>RODINA, PÁR, TĚHOTENSTVÍ, PORTRÉT<br>&nbsp;&nbsp;<br><br></a><br></span>"}>
              </Title>

              <Text className="text-box fs--102" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-kwvb7s pb--60 pt--60" name={"kwkbd1vo1lk"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--102" content={"<br>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/batole-sitter-narozeniny\"><br><br><br>BATOLE, SITTER, NAROZENINY<br><br><br></a><br></span>"}>
              </Title>

              <Text className="text-box fs--102" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xxnpwrlkon"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--20 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Michaela Synáčová Fotografie<br></span><br>"}>
              </Subtitle>

              <Title className="title-box fs--26 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">NEWBORN A RODINNÁ FOTOGRAFKA</span>"}>
              </Title>

              <Text className="text-box fs--14 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">5.května 653, Blovice</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">+420 739 449 891</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert mt--0" content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">michaela.synacova.fotografie@gmail.com</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn2 fs--16" href={"https://www.facebook.com/michaelasynacova.fotografie"} content={"<span style=\"color: rgb(0, 0, 0);\">facebook</span>"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 fs--16" href={"https://www.instagram.com/michaela.synacova.fotografie/"} content={"<span style=\"color: rgb(0, 0, 0);\">instagram</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}